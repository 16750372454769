import React from 'react'

import ContentOrCollectionTypeIcon from '../../../ContentOrCollectionTypeIcon'
import { collectionTypeIds } from '../../../../app/_constants'
import { Typography } from '@mui/material'

const ContentOrCollectionSummary = ({
	title,
	icon,
	collectionTypeSlug,
	value
}) => {
	const SummaryIcon = icon
	return (
		<>
			{icon && (
				<SummaryIcon
					style={{
						width: `24px`,
						height: `24px`,
						filter: 'contrast(1000%)'
					}}
				></SummaryIcon>
			)}
			{collectionTypeSlug && (
				<i style={{ filter: 'contrast(1000%)' }}>
					<ContentOrCollectionTypeIcon
						id={collectionTypeIds[collectionTypeSlug]}
						size={24}
						color="#194D48"
					/>
				</i>
			)}
			<Typography variant="h5" color="black">
				{value}
			</Typography>
			<Typography variant="body2">{title}</Typography>
			{/* {subItem &&
				subItem.length > 0 &&
				subItem.map((item, key) => (
					<div key={key}>
						<h3>{item.title}</h3>
						<S.ItemDesc>
							<Icon
								type={item.icon}
								style={item.iconStyle}
							></Icon>
							<h2>{item.value}</h2>
						</S.ItemDesc>
					</div>
				))} */}{' '}
		</>
	)
}

export default ContentOrCollectionSummary
