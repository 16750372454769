import { TextField } from '@mui/material'
import styled from 'styled-components'

export const Panel = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
	padding-top: 16px;
`
export const StyledInput = styled(TextField)`
	background-color: #fff;
`
