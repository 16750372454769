import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { Button, Pagination } from 'semantic-ui-react'

import { contentActions } from '../../../app/_actions'
import noImg from '../../../assets/no-image.jpg'

import * as ListStyle from '../../../pages/Dashboard/Content/List/styles.js'

import PageHeader from '../PageHeader'
import { Alert, Divider } from '@mui/material'
import LandingPageContentBlock from '../../PublicPages/LandingPageContentBlock'
import i18n from '../../../i18n'

const PER_PAGE = 5
function MyFavorites({ title = 'Minha lista', showMore, header = null }) {
	const [page, setPage] = useState(1)
	const dispatch = useDispatch()
	const onBlockClick = (content) => {
		const win =
			i18n.language == 'es-ES'
				? window.open(`/contenido/${content.friendly_url}`, '_blank')
				: window.open(`/conteudo/${content.friendly_url}`, '_blank')
		if (win != null) {
			win.focus()
		}
	}
	useEffect(() => {
		dispatch(contentActions.getUserFavorites(page, PER_PAGE))
	}, [dispatch, page])

	// @ts-ignore
	const { favorite } = useSelector((state) => state.content)

	return (
		<>
			{header || <PageHeader title={title} />}
			{favorite && favorite.content ? (
				<ListStyle.Wrapper>
					{favorite && favorite.content.length > 0 ? (
						favorite.content.map((content, idx) => (
							<Fragment key={content.id}>
								<LandingPageContentBlock
									title={content.title}
									description={content.description}
									img={content.cover_id || noImg}
									onClick={() => onBlockClick(content)}
									contentType={content.content_type}
								></LandingPageContentBlock>
								{idx < favorite.content.length - 1 && (
									<Divider
										style={{
											width: '100%',
											borderColor: 'rgba(0, 0, 0, 0.0)'
										}}
									/>
								)}
							</Fragment>
						))
					) : (
						<Alert severity="info" sx={{ width: '100%' }}>
							Nenhum conteúdo salvo nos últimos 30 dias.{' '}
						</Alert>
					)}
					{showMore ? (
						<NavLink to="/painel/minha-conta/favoritos">
							<Button>Ver todos os favoritos</Button>
						</NavLink>
					) : (
						<Pagination
							defaultActivePage={page}
							totalPages={Math.ceil(
								favorite.totalResults / PER_PAGE
							)}
							onPageChange={(e, { activePage }) => {
								e.preventDefault()
								// @ts-ignore
								setPage(activePage)
							}}
						/>
					)}
				</ListStyle.Wrapper>
			) : (
				<h3>
					Você ainda não favoritou nenhum dos conteúdos disponíveis.
				</h3>
			)}
		</>
	)
}

export default MyFavorites
