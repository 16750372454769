import { authHeader } from '../auth-header'
import { alertActions } from '../_actions'

const config = {
	apiUrl: process.env.REACT_APP_API
}

export const userService = {
	login,
	logout,
	getForgotPassword,
	setNewPassword,
	confirmEmail,
	changePassword,
	changePhoto,
	register,
	loadUsersSelection,
	loadUsersOrganizationSelection,
	loadUsersCurator,
	getAll,
	getById,
	updateUser,
	getUser,
	delete: _delete,
	getMyComments,
	setUserRole
}

function login(email, password, token) {
	const requestOptions = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify({ email, password, 'g-recaptcha-response': token })
	}

	return fetch(`${config.apiUrl}/auth/login`, requestOptions)
		.then(handleResponse)
		.then((token) => {
			return token
		})
}

function logout() {
	// remove user from local storage to log user out
	localStorage.removeItem('token')
}

function getForgotPassword(email) {
	const requestOptions = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify({ email })
	}

	return fetch(
		`${config.apiUrl}/auth/reset-password-token`,
		requestOptions
	).then(handleResponse)
}

function setNewPassword(newPassword, passwordConfirmation, token) {
	const requestOptions = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify({ newPassword, passwordConfirmation, token })
	}

	return fetch(`${config.apiUrl}/auth/reset-password`, requestOptions).then(
		handleResponse
	)
}

function confirmEmail(token) {
	const requestOptions = {
		method: 'GET'
	}

	return fetch(
		`${config.apiUrl}/auth/confirm-email?token=${token}`,
		requestOptions
	).then(handleResponse)
}

function changePassword(currentPassword, newPassword, passwordConfirmation) {
	const requestOptions = {
		method: 'PUT',
		headers: {
			'Content-Type': 'application/json',
			...authHeader()
		},
		body: JSON.stringify({
			currentPassword,
			newPassword,
			passwordConfirmation
		})
	}

	return fetch(`${config.apiUrl}/users/password/change`, requestOptions).then(
		handleResponse
	)
}

function changePhoto(file) {
	let formData = new FormData()
	formData.append('files', file)

	const requestOptions = {
		method: 'POST',
		headers: {
			...authHeader()
		},
		body: formData
	}

	return fetch(`${config.apiUrl}/users/photo`, requestOptions).then(
		handleResponse
	)
}

async function loadUsersSelection(q, loadedOptions, { page }) {
	const requestOptions = {
		method: 'GET',
		headers: {
			...authHeader()
		}
	}
	return await fetch(
		`${config.apiUrl}/users/paginate/${page}/10?q=${q}`,
		requestOptions
	)
		.then((response) => {
			if (response.ok) {
				return response.json()
			} else {
				alertActions.error(response.message)
				return Promise.reject(response)
			}
		})
		.then((response) => {
			const hasMore = response.has_next
			return {
				options: response.users.map((o) => ({
					value: o.id,
					label: o.name
				})),
				hasMore,
				additional: {
					page: hasMore ? page + 1 : page
				}
			}
		})
		.catch(() => {
			return {
				options: [
					{
						value: 'erro',
						label: 'Erro ao recuperar lista de usuário',
						disabled: true
					}
				],
				isDisabled: true
			}
		})
}

async function loadUsersOrganizationSelection(q, loadedOptions, { page }) {
	const requestOptions = {
		method: 'GET',
		headers: {
			...authHeader()
		}
	}
	return await fetch(
		`${config.apiUrl}/users/role-organization/paginate/${page}/10?q=${q}`,
		requestOptions
	)
		.then((response) => {
			if (response.ok) {
				return response.json()
			} else {
				alertActions.error(response.message)
				return Promise.reject(response)
			}
		})
		.then((response) => {
			const hasMore = response.has_next
			return {
				options: response.users.map((o) => ({
					value: o.id,
					label: o.name
				})),
				hasMore,
				additional: {
					page: hasMore ? page + 1 : page
				}
			}
		})
		.catch(() => {
			return {
				options: [
					{
						value: 'erro',
						label: 'Erro ao recuperar lista de curadores',
						disabled: true
					}
				],
				isDisabled: true
			}
		})
}

async function loadUsersCurator(q, loadedOptions, { page }) {
	const requestOptions = {
		method: 'GET',
		headers: {
			...authHeader()
		}
	}
	return await fetch(
		`${config.apiUrl}/users/role-curator/paginate/${page}/10?q=${q}`,
		requestOptions
	)
		.then((response) => {
			if (response.ok) {
				return response.json()
			} else {
				alertActions.error(response.message)
				return Promise.reject(response)
			}
		})
		.then((response) => {
			const hasMore = response.has_next
			return {
				options: response.users.map((o) => ({
					value: o.id,
					label: o.name
				})),
				hasMore,
				additional: {
					page: hasMore ? page + 1 : page
				}
			}
		})
		.catch(() => {
			return {
				options: [
					{
						value: 'erro',
						label: 'Erro ao recuperar lista de curadores',
						disabled: true
					}
				],
				isDisabled: true
			}
		})
}

function getAll(page, perPage, q = '') {
	const requestOptions = {
		method: 'GET',
		headers: authHeader()
	}

	return fetch(
		`${config.apiUrl}/users/paginate/${page}/${perPage}?q=${q}`,
		requestOptions
	).then(handleResponse)
}

function getById(id) {
	const requestOptions = {
		method: 'GET',
		headers: authHeader()
	}

	return fetch(`${config.apiUrl}/users/${id}`, requestOptions).then(
		handleResponse
	)
}

function register(user) {
	const requestOptions = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(user)
	}

	return fetch(`${config.apiUrl}/users/`, requestOptions).then(handleResponse)
}

function updateUser(id, data) {
	const requestOptions = {
		method: 'PUT',
		headers: { ...authHeader(), 'Content-Type': 'application/json' },
		body: JSON.stringify(data)
	}

	return fetch(`${config.apiUrl}/users/${id}`, requestOptions).then(
		handleResponse
	)
}

function getUser(id) {
	const requestOptions = {
		method: 'GET',
		headers: { ...authHeader(), 'Content-Type': 'application/json' }
	}

	return fetch(`${config.apiUrl}/users/${id}`, requestOptions).then(
		handleResponse
	)
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
	const requestOptions = {
		method: 'DELETE',
		headers: authHeader()
	}

	return fetch(`${config.apiUrl}/users/${id}`, requestOptions).then(
		handleResponse
	)
}

function getMyComments(page, per_page) {
	const requestOptions = {
		method: 'GET',
		headers: authHeader()
	}

	return fetch(
		`${config.apiUrl}/users/my-comments/${page}/${per_page}`,
		requestOptions
	).then(handleResponse)
}

function setUserRole(userId, data) {
	const requestOptions = {
		method: 'PUT',
		headers: { ...authHeader(), 'Content-Type': 'application/json' },
		body: JSON.stringify(data)
	}

	return fetch(`${config.apiUrl}/users/${userId}/admin`, requestOptions).then(
		handleResponse
	)
}

function handleResponse(response) {
	return response.text().then((text) => {
		const data = text && JSON.parse(text)
		if (!response.ok) {
			if (response.status === 401) {
				// auto logout if 401 response returned from api
				logout()
			}

			const error = (data && data.message) || response.statusText
			return Promise.reject(error)
		}

		return data
	})
}
