import { contentConstants } from '../_constants'

export function content(
	state = {
		object: {},
		loading: false,
		data: null,
		loadingImage: false
	},
	action
) {
	switch (action.type) {
		case contentConstants.REQUEST:
		case contentConstants.USER_CONTENT_REQUEST:
		case contentConstants.UPDATE_REQUEST:
		case contentConstants.SET_REQUEST:
		case contentConstants.SET_FILE_REQUEST:
		case contentConstants.CREATE_REQUEST:
		case contentConstants.PUBLISH_REQUEST:
		case contentConstants.REQUEST_CONTENT_URL:
			return {
				...state,
				loading: true
			}
		case contentConstants.SUCCESS:
		case contentConstants.USER_CONTENT_SUCCESS:
			return {
				...state,
				data: action.data,
				loading: false
			}
		case contentConstants.CREATE_SUCCESS:
		case contentConstants.UPDATE_SUCCESS:
		case contentConstants.SUCCESS_CONTENT_URL:
			return {
				...state,
				object: action.data,
				loading: false
			}
		case contentConstants.ERROR:
		case contentConstants.USER_CONTENT_FAILURE:
		case contentConstants.CLEAN:
			return {
				...state,
				object: {},
				loading: false
			}
		case contentConstants.CONTENT_TYPE_REQUEST:
			return {
				...state,
				[action.id]: { loading: true }
			}
		case contentConstants.CONTENT_TYPE_SUCCESS:
			return {
				...state,
				[action.id]: { content: action.data.content }
			}
		case contentConstants.SET_SUCCESS:
		case contentConstants.UPDATE_FAILURE:
		case contentConstants.DELETE_SUCCESS:
			return {
				...state,
				loading: false
			}
		case contentConstants.SUCCESS_FAVORITE:
			return {
				...state,
				loading: false,
				favorite: action.data
			}
		case contentConstants.SET_COVER_REQUEST:
			return {
				...state,
				loadingImage: true
			}
		case contentConstants.SET_COVER_SUCCESS:
			return {
				...state,
				loadingImage: false,
				object: {
					...state.object,
					cover_id: action.data.cover_url || null,
					cover_filename: action.data.cover_filename || null
				}
			}
		case contentConstants.SET_COVER_FAILURE:
			return {
				...state,
				loadingImage: false
			}
		case contentConstants.SET_FILE_SUCCESS:
			return {
				...state,
				selected: {}
			}
		case contentConstants.CONTENT_ACTION_SUCCESS:
			return {
				...state,
				object: {
					...state.object,
					is_favorited_by_current_user:
						'favorited' in action.data
							? action.data.favorited
							: state.object.is_favorited_by_current_user,
					is_liked_by_current_user:
						'liked' in action.data
							? action.data.liked
							: 'disliked' in action.data &&
							  action.data.disliked === true
							? false
							: state.object.is_liked_by_current_user,
					is_disliked_by_current_user:
						'disliked' in action.data
							? action.data.disliked
							: 'liked' in action.data &&
							  action.data.liked === true
							? false
							: state.object.is_disliked_by_current_user,
					number_of_likes: action.data.number_of_likes || 0
				}
			}
		case contentConstants.FAILURE:
		case contentConstants.FAILURE_CONTENT_URL:
		case contentConstants.CONTENT_ACTION_REQUEST:
		case contentConstants.CONTENT_ACTION_FAILURE:
		case contentConstants.SET_FAILURE:
		case contentConstants.DELETE_FAILURE:
		case contentConstants.PUBLISH_SUCCESS:
		case contentConstants.PUBLISH_FAILURE:
		case contentConstants.CONTENT_TYPE_FAILURE:
		case contentConstants.SET_TYPE_THEMES_REQUEST:
		case contentConstants.SET_TYPE_THEMES_SUCCESS:
		case contentConstants.SET_FILE_FAILURE:
		case contentConstants.SET_TYPE_THEMES_FAILURE:
			return {
				...state,
				loading: false
			}
		default:
			return state
	}
}
