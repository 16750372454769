import styled from 'styled-components'

export const Dashboard = styled.div`
	min-height: calc(100vh - 200px);
	background-color: #f4f4f4;
`

export const ContentWrapper = styled.div`
	margin-left: 210px;
	padding: 16px;
	padding-right: 32px;
	display: flex;
	flex-direction: column;
	gap: 16px;

	.breadcrumb a {
		display: inline-block;
		color: rgba(0, 0, 0, 0.7);
		:hover {
			color: ${({ theme }) => theme.colors.secondary};
		}
		:first-letter {
			text-transform: uppercase;
		}
		:last-child {
			pointer-events: none;
		}
	}
	@media screen and (max-width: 782px) {
		margin-left: 0;
		padding-right: 16px;
	}
`
