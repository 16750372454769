import { authHeader } from '../auth-header'
import { handleResponse } from '../handle-response'

const config = {
	apiUrl: process.env.REACT_APP_API
}

export const metricsService = {
	getPainelMetrics,
	getContentTypesMetrics,
	getThemesMetrics
}

function getPainelMetrics() {
	const requestOptions = {
		method: 'GET',
		headers: { ...authHeader() }
	}
	return fetch(`${config.apiUrl}/metrics/painel`, requestOptions).then(
		handleResponse
	)
}

function getContentTypesMetrics() {
	const requestOptions = {
		method: 'GET',
		headers: { ...authHeader() }
	}
	return fetch(`${config.apiUrl}/metrics/content-types`, requestOptions).then(
		handleResponse
	)
}

function getThemesMetrics() {
	const requestOptions = {
		method: 'GET',
		headers: { ...authHeader() }
	}
	return fetch(`${config.apiUrl}/metrics/themes`, requestOptions).then(
		handleResponse
	)
}
