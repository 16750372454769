import { contentDataConstants } from '../_constants'

export function themes(state = { themes: [], loading: false }, action) {
	switch (action.type) {
		case contentDataConstants.REQUEST_THEMES:
			return {
				...state,
				loading: true
			}
		case contentDataConstants.SUCCESS_THEMES:
			return {
				themes: action.rows || [],
				loading: false
			}
		case contentDataConstants.FAILURE_THEMES:
			return {
				...state,
				themes: [],
				loading: false
			}
		default:
			return state
	}
}
