import { TextField } from '@mui/material'
import styled from 'styled-components'

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	border-radius: 4px;
  gap: 16px;
	'& .MuiPaginationItem-root': {
		borderRadius: 0
	},
`

export const Header = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0px 16px;
`

export const TabsContainer = styled.div`
	display: flex;
	justify-content: center;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	backgroundcolor: '#fff';
	.MuiTabs-indicator {
		background: #d56115;
	}
	.MuiButtonBase-root {
		font-size: 15px;
		flex: 1;
		font-weight: 400;
		color: rgba(0, 0, 0, 0.54);
	}
	.MuiButtonBase-root[aria-selected='true'] {
		color: #194d48;
	}
	.MuiTabs-root {
		width: 100%;
	}
	@media screen and (max-width: 782px) {
		flex-direction: column;
		gap: 8px;
	}
`
export const Title = styled.span`
	font-size: 23px;
	font-weight: 400;
	color: ${({ theme }) => theme.colors.primary};
`

export const StyledInput = styled(TextField)`
	background-color: #fff;
`
