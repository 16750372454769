import { createTheme } from '@mui/material'

const { palette } = createTheme()
const { augmentColor } = palette
const createColor = (mainColor) => augmentColor({ color: { main: mainColor } })

const muiTheme = createTheme({
	typography: {
		body2: {
			color: 'var(--black-06, rgba(0, 0, 0, 0.60))'
		},
		fontSize: 22,
		button: {
			textTransform: 'none'
		}
	},
	palette: {
		primary: createColor('#194D48'),
		secondary: createColor('rgb(220, 94, 20)'),
		light: createColor('#dddddd'),
		shadowButton: createColor('#dddddd')
	}
})
export default muiTheme
