import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import xor from 'lodash.xor'
import Step from '@mui/material/Step'
import StepButton from '@mui/material/StepButton'
import Stepper from '@mui/material/Stepper'
import Typography from '@mui/material/Typography'

import { contentActions, orgsActions } from '../../../../app/_actions'
import { stepInputs, steps } from './steps'
import * as S from './styles'
import { isArrayEqual, isTmpId, slugify } from '../../../../utils/functions'

import EditContentTypeAndFile from '../../EditSteps/EditContentTypeAndFile'
import EditCommonFields from '../../EditSteps/EditCommonFields'
import EditContentExtras from '../../EditSteps/EditContentExtras'
import EditDialogFooter from '../../EditDialogFooter'
import { STATUS } from '../../../../constants'

function EditContentDialog({
	handleClose,
	initialContentId,
	isModalOpen,
	setSelectedContentId,
	openPublishModal,
	onUpdate = null,
	skipPublishDialogOnFinish = false
}) {
	const dispatch = useDispatch()

	const [imageSrc, setImageSrc] = useState(null)
	const [croppedImage, setCroppedImage] = useState(null)
	const [inputs, setInputs] = useState(stepInputs)
	const [step, setStep] = useState(0)
	const [messageError, setMessageError] = useState(null)
	const [showFooter, setShowFooter] = useState(true)
	const messageErrorRef = useRef(null)

	const {
		object: content,
		loadingImage,
		loading
	} = useSelector(
		// @ts-ignore
		(state) => state.content
	)

	// step 0:
	const submitContentTypeAndFile = (currContent, currInputs) => {
		function saveFile(currFile, newContentId = null) {
			if (
				currFile &&
				(newContentId || currFile.name !== currContent.file_filename)
			) {
				dispatch(
					contentActions.setFile(
						newContentId || currContent.id,
						currFile,
						null,
						(error) => {
							setMessageError(error)
							setStep(0)
						}
					)
				)
			}
		}

		const { content_type_id, content_link, file } = currInputs
		if (!content_type_id) {
			setMessageError('Selecione um tipo de conteúdo')
			return false
		}
		if (!file && !content_link) {
			setMessageError(
				'Informe o conteúdo através do link ou selecione um arquivo'
			)
			return false
		}

		if (!currContent.id) {
			// Create first content
			dispatch(
				contentActions.createContent(
					{
						content_type_id,
						content_link
					},
					(data) => {
						setSelectedContentId(data.id)
						if (onUpdate) {
							onUpdate(data)
						}
						saveFile(file, data.id)
					},
					(error) => {
						setMessageError(error)
						setStep(0)
					}
				)
			)
			return true
		}

		if (
			currContent.content_type_id !== content_type_id ||
			currContent.content_link !== content_link ||
			file.name !== currContent.file_filename
		) {
			dispatch(
				contentActions.updateContent(
					currContent.id,
					{
						content_type_id,
						content_link,
						status: [
							STATUS.AGUARDANDO_APROVACAO,
							STATUS.APROVADO
						].includes(currContent.status)
							? 4
							: currContent.status
					},
					onUpdate,
					(error) => {
						setMessageError(error)
						setStep(0)
					}
				)
			)
		}
		saveFile(file)

		return true
	}

	// step 1:
	const submitCommonFields = (
		currContent,
		currInputs,
		currImageSrc,
		currCroppedImage
	) => {
		async function saveImage(collectionId) {
			if (currImageSrc && currCroppedImage) {
				const blob = await new Promise((resolve) => {
					currCroppedImage.canvas.toBlob((file) => {
						resolve(file)
					})
				})
				dispatch(
					contentActions.setCover(
						collectionId,
						blob,
						onUpdate,
						(error) => {
							setMessageError(error)
							setStep(1)
						}
					)
				)
			}
		}

		const { title, description } = currInputs
		if (!title) {
			setMessageError('Informe o título do conteúdo')
			return false
		}
		if (!description) {
			setMessageError('Preencha a descrição do conteúdo')
			return false
		}
		if (!currContent.cover_id && !currImageSrc) {
			setMessageError('Selecione uma imagem de capa')
			return false
		}

		if (
			currContent.title !== title ||
			currContent.description !== description
		) {
			dispatch(
				contentActions.updateContent(
					currContent.id,
					{
						title,
						description,
						status: [
							STATUS.AGUARDANDO_APROVACAO,
							STATUS.APROVADO
						].includes(currContent.status)
							? 4
							: currContent.status
					},
					onUpdate,
					(error) => {
						setMessageError(error)
						setStep(1)
					}
				)
				// @ts-ignore
			)
		}
		if (currImageSrc) {
			saveImage(currContent.id)
		}
		return true
	}

	// step 2:
	const submitContentExtras = (currContent, currInputs) => {
		const preparedInputs = { ...currInputs }
		const keys = [
			'organizations',
			'bioma_id',
			'pais_id',
			'estado_id',
			'land_categories',
			'language_id',
			'themes'
		]
		for (const fieldKey of keys) {
			if (currInputs[fieldKey]) {
				preparedInputs[fieldKey] = [
					...currInputs[fieldKey].map((input) => input.id)
				]
			}
		}
		const {
			year,
			author,
			how_cite,
			keywords,
			organizations,
			bioma_id,
			pais_id,
			estado_id,
			land_categories,
			language_id,
			themes
		} = preparedInputs

		if (!keywords.length || !themes.length || !author.length) {
			setMessageError(
				'Preencha os campos obrigatórios para que seu conteúdo possa ser encontrado nos mecanismos de busca do portal.'
			)
			if (messageErrorRef.current) {
				messageErrorRef.current.scrollIntoView({
					behavior: 'smooth'
				})
			}
			return false
		}

		if (
			currContent.year !== year ||
			(currContent.author &&
				!isArrayEqual(author, currContent.author.split(', '))) ||
			(currContent.keywords &&
				!isArrayEqual(keywords, currContent.keywords.split(', '))) ||
			how_cite !== currContent.how_cite ||
			xor(currContent.bioma_ids, bioma_id).length ||
			xor(currContent.pais_ids, pais_id).length ||
			xor(currContent.estado_ids, estado_id).length ||
			xor(currContent.land_categories, land_categories).length ||
			xor(currContent.language_ids, language_id).length ||
			xor(
				currContent.organizations.map((org) => org.id),
				organizations
			).length ||
			xor(
				currContent.themes.map((theme) => theme.id),
				themes
			).length
		) {
			dispatch(
				contentActions.updateContent(
					currContent.id,
					{
						year,
						author: author.join(', '),
						keywords: keywords.join(', '),
						how_cite,
						bioma_id,
						pais_id,
						estado_id,
						land_categories,
						organizations,
						language_id,
						themes,
						status: [
							STATUS.AGUARDANDO_APROVACAO,
							STATUS.APROVADO
						].includes(currContent.status)
							? 4
							: currContent.status
					},
					onUpdate,
					(error) => {
						setMessageError(error)
						setStep(2)
					}
				)
			)
			return true
		}
	}

	const handleSubmit = (stepToSubmit) => {
		if (loading || loadingImage) {
			return
		}
		if (stepToSubmit > 0 && !content.id) {
			setMessageError(
				'Alguma falha impediu o conteúdo ser criado. Por favor, volte ao passo anterior e tente novamente.'
			)
			return
		}
		let success = false
		switch (stepToSubmit) {
			case 0:
				success = submitContentTypeAndFile(content, inputs)
				break
			case 1:
				success = submitCommonFields(
					content,
					inputs,
					imageSrc,
					croppedImage
				)
				break
			case 2:
				success = submitContentExtras(content, inputs)
				if (success) {
					setMessageError(null)
					setStep(0)

					if (skipPublishDialogOnFinish) {
						handleCloseModal()
						return
					}

					openPublishModal()
				}
				return
		}

		if (success) {
			setMessageError(null)
			setStep(stepToSubmit + 1)
		}
	}

	const handleChangeInputs = useCallback(({ target }) => {
		const { name, value: newValuesRaw } = target

		// If there are new values from FreeSolo AutoComplete, the options must be created on server:
		if (
			Array.isArray(newValuesRaw) &&
			newValuesRaw.find((newValue) => isTmpId(newValue.id))
		) {
			const valuesToBeCreated = newValuesRaw.filter((newValue) =>
				isTmpId(newValue.id)
			)
			let createAction = null
			let loadListAction = () => {}
			switch (name) {
				case 'organizations':
					createAction = orgsActions.createBatch
					loadListAction = orgsActions.getAll
					break
			}

			if (createAction && loadListAction) {
				const requestBody = {
					orgs: valuesToBeCreated.map((val) => ({
						name: val.name
					}))
				}
				const onUpdateInputs = (data) => {
					const newValues = newValuesRaw.map((newValue) => {
						const newValueSlug = slugify(newValue.name)
						const idsToBeCreated = valuesToBeCreated.map(
							(val) => val.id
						)
						return idsToBeCreated.includes(newValue.id)
							? data.find(
									(row) => slugify(row.name) === newValueSlug
							  )
							: newValue
					})
					setInputs((prevInputs) => ({
						...prevInputs,
						[name]: newValues
					}))
				}

				dispatch(createAction(requestBody, onUpdateInputs))
				return
			}
		}

		// If all values are existing values in options, only update Inputs:
		setInputs((prevInputs) => ({ ...prevInputs, [name]: newValuesRaw }))
	}, [])

	const handleChangeFile = useCallback(async (e) => {
		if (e.target.files && e.target.files.length > 0) {
			const newFile = e.target.files[0]
			setInputs((prevInputs) => ({
				...prevInputs,
				file: newFile
			}))
		}
	}, [])

	useEffect(() => {
		if (content.id) {
			setInputs((prevInputs) => ({
				...prevInputs,
				title: content.title || '',
				description: content.description || '',
				content_type_id: content.content_type_id,
				themes: content.themes,
				organizations: content.organizations,
				content_categories: content.content_categories
					? content.content_categories.map((t) => t.id)
					: [],
				content_link: content.content_link,
				file: { file: content.file_id, name: content.file_filename },
				author: content.author ? content.author.split(', ') : [],
				how_cite: content.how_cite || '',
				year: content.year ? content.year.toString() : null,
				language_id: content.language_ids
					? content.language_ids.map((languageId) => {
							return { id: languageId }
					  })
					: [],
				contributors: content.contributors
					? content.contributors.split(', ')
					: [],
				keywords: content.keywords ? content.keywords.split(', ') : [],
				bioma_id: content.bioma_ids
					? content.bioma_ids.map((biomaId) => {
							return { id: biomaId }
					  })
					: [],
				pais_id: content.pais_ids
					? content.pais_ids.map((paisId) => {
							return { id: paisId }
					  })
					: [],
				estado_id: content.estado_ids
					? content.estado_ids.map((estadoId) => {
							return { id: estadoId }
					  })
					: [],
				municipio_id: content.municipio_ids
					? content.municipio_ids
					: [],
				land_categories: content.land_categories
			}))
			return
		}
		setInputs(stepInputs)
	}, [content.id])

	useEffect(() => {
		if (initialContentId) {
			dispatch(contentActions.getContentById(initialContentId))
			return
		}

		setImageSrc(null)
		setCroppedImage(null)
	}, [dispatch, initialContentId])

	const handleCloseModal = () => {
		dispatch(contentActions.cleanContent())
		setCroppedImage(null)
		setStep(0)
		handleClose()
	}

	const cover = content
		? { filename: content.cover_filename, image: content.cover_id }
		: null

	return (
		<S.StyledModal open={isModalOpen} onClose={handleCloseModal}>
			<S.Panel>
				<S.Wrapper>
					<S.StepperContainer activeStep={step}>
						<Stepper activeStep={step}>
							{steps.map((item) => (
								<Step key={item.title}>
									<StepButton color="inherit">
										{item.title}
										<Typography variant="caption">
											{item.description}
										</Typography>
									</StepButton>
								</Step>
							))}
						</Stepper>
					</S.StepperContainer>
				</S.Wrapper>
				<S.StepContainer>
					{step === 0 && (
						<EditContentTypeAndFile
							inputs={inputs}
							onChange={handleChangeInputs}
							onChangeFile={handleChangeFile}
							messageError={messageError}
						/>
					)}
					{step === 1 && (
						<EditCommonFields
							inputs={inputs}
							onChange={handleChangeInputs}
							imageSrc={imageSrc}
							setImageSrc={setImageSrc}
							setCroppedImage={setCroppedImage}
							currentCoverImage={croppedImage || cover}
							messageError={messageError}
							setShowFooter={setShowFooter}
						/>
					)}
					{step === 2 && (
						<EditContentExtras
							inputs={inputs}
							onChange={handleChangeInputs}
							messageError={messageError}
							messageErrorRef={messageErrorRef}
						/>
					)}
				</S.StepContainer>
				<EditDialogFooter
					showFooter={showFooter}
					handleCloseModal={handleCloseModal}
					step={step}
					setStep={setStep}
					handleSubmit={handleSubmit}
					loading={loading}
					steps={steps}
				/>
			</S.Panel>
		</S.StyledModal>
	)
}

export default memo(EditContentDialog)
