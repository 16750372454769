import React, { useCallback, useEffect } from 'react'
import ReactTooltip from 'react-tooltip'
import { useHistory } from 'react-router-dom'

import usePrevious from '../../../utils/hooks/usePrevious'
import { useFilter } from '../../../context/advancedFilter'

import { useTranslation } from 'react-i18next'

import * as S from './styles'
import SearchBox from '../SearchBox'
import useWindowDimensions from '../../../utils/hooks/useWindowDimensions'

const Search = () => {
	const { filter, setFilter } = useFilter()
	const prevFilter = usePrevious(filter)
	const { t, i18n } = useTranslation()
	const history = useHistory()
	const { width } = useWindowDimensions()

	function handleChange({ target }) {
		const { name, value } = target
		setFilter((filter) => ({ ...filter, [name]: value }))
	}

	const handleFilter = useCallback(() => {
		if (i18n.language == 'es-ES') {
			history.push({
				pathname: '/resultado-busqueda?busca=' + filter.title
			})
		} else {
			history.push({
				pathname: '/resultado-busca?busca=' + filter.title
			})
		}
		window.location.reload(false)
	}, [filter])

	/* Ativa filtro ao dar "Enter" no input de texto */
	function handleKeyDown(event) {
		if (event.key === 'Enter') {
			handleFilter()
		}
	}

	useEffect(() => {
		if (
			prevFilter &&
			(prevFilter.content_type_id != filter.content_type_id ||
				prevFilter.theme_id != filter.theme_id)
		) {
			handleFilter()
		}
		ReactTooltip.rebuild()
	}, [handleFilter, filter, prevFilter])

	return (
		<div className="container">
			<S.Wrapper>
				<S.InputWrapper>
					<S.Title>
						{t(
							'search.browse-library',
							'Uma biblioteca digital sobre as áreas protegidas do Brasil e do mundo'
						)}
					</S.Title>
					<SearchBox
						isHome={true}
						value={filter.title}
						handleChange={handleChange}
						handleFilter={handleFilter}
						handleKeyDown={handleKeyDown}
						autoFocus={width > 782 ? true : false}
					/>
				</S.InputWrapper>
			</S.Wrapper>
		</div>
	)
}

export default Search
