import React, { useCallback, useState } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Button from '@mui/material/Button'
import PreviewOutlinedIcon from '@mui/icons-material/PreviewOutlined'
import CheckIcon from '@mui/icons-material/Check'
import WarningIcon from '@mui/icons-material/Warning'
import ErrorIcon from '@mui/icons-material/Error'
import EditIcon from '@mui/icons-material/Edit'

import * as S from './styles'
import noImg from '../../../assets/no-image.jpg'
import noCollectionContents from '../../../assets/no-collections.png'
import ContentOrCollectionTypeIcon from '../../ContentOrCollectionTypeIcon'
import CollectionSheet from '../../PublicPages/CollectionSheet'
import Alert from '@mui/material/Alert'

import {
	getJustification,
	getJustificationAlertType,
	getStatusAlertTitle
} from '../../../utils/functions'
import { STATUS } from '../../../constants'
import { collectionsConstants } from '../../../app/_constants'

import CustomTabPanel from '../CustomTabPanel'
import List from '../../../pages/Dashboard/Content/List'

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`
	}
}

function CollectionDetails({
	item,
	isCurator = false,
	isPage = false,
	handleEditCollectionContents = null
}) {
	const [tabIndexValue, setTabIndexValue] = useState(0)
	const justification = getJustification(item)
	const {
		title,
		cover_id,
		collection_type,
		friendly_url,
		description,
		status,
		content_indications
	} = item
	const collectionTypeGenre = collection_type ? collection_type.genre : ''
	const collectionTypeSlug = collection_type ? collection_type.slug : ''
	const collectionTypeName = collection_type
		? collection_type.name.toLowerCase()
		: ''

	const checkFilled = () => {
		if (!item) {
			return []
		}

		let fieldsToBeChecked = [
			// STEP 1:
			'collection_type',
			'title',
			'description',
			'cover_id',

			// STEP 2:
			'content_indications',

			// STEP 3:
			'language_ids',
			'themes',
			'keywords',

			// Ficha Técnica - Curso and Projeto:
			'date_start',
			'date_end',

			// Ficha Técnica - Curso only:
			'curso_type',
			'curso_workload',
			'curso_program',

			// Ficha Técnica - Projeto only:
			'projeto_financers',
			'projeto_url'
		]

		switch (collection_type.slug) {
			case collectionsConstants.COLLECTION_TYPE_SLUG.SERIE:
				fieldsToBeChecked = fieldsToBeChecked.filter(
					(field) =>
						![
							'date_start',
							'date_end',
							'curso_type',
							'curso_workload',
							'curso_program',
							'projeto_financers',
							'projeto_url'
						].includes(field)
				)
				break
			case collectionsConstants.COLLECTION_TYPE_SLUG.CURSO:
				fieldsToBeChecked = fieldsToBeChecked.filter(
					(field) =>
						!['projeto_financers', 'projeto_url'].includes(field)
				)
				break
			case collectionsConstants.COLLECTION_TYPE_SLUG.PROJETO:
				fieldsToBeChecked = fieldsToBeChecked.filter(
					(field) =>
						![
							'curso_type',
							'curso_workload',
							'curso_program'
						].includes(field)
				)
				break
		}

		const emptyFields = fieldsToBeChecked.filter((key) => {
			if (!(key in item) || !item[key]) {
				return true
			}
			if (Array.isArray(item[key])) {
				return item[key].length === 0
			}
			return false
		})

		return emptyFields
	}

	const emptyFields = checkFilled()

	const onPreviewClick = () => {
		const url =
			status == 1
				? `/${collectionTypeSlug}/`
				: `/pre-visualizar/${collectionTypeSlug}/`
		const win = window.open(url + friendly_url, '_blank')
		if (win != null) {
			win.focus()
		}
	}

	const handleTabChange = useCallback((_, newValue) => {
		setTabIndexValue(newValue)
	}, [])

	const AlertIcon = () => {
		switch (item.status) {
			case STATUS.REJEITADA_ANALISE:
			case STATUS.REJEITADO:
				return <ErrorIcon fontSize="inherit" />
			case STATUS.APROVADO:
				return <CheckIcon fontSize="inherit" />
			case STATUS.AGUARDANDO_APROVACAO:
			default:
				return <WarningIcon fontSize="inherit" />
		}
	}

	const CollectionDetailsInner = () => (
		<>
			{![STATUS.RASCUNHO].includes(item.status) && (
				<Alert
					icon={AlertIcon()}
					severity={getJustificationAlertType(item)}
					sx={{ my: 2 }}
				>
					<Typography variant="h5">
						{getStatusAlertTitle(item)}
					</Typography>
					<Typography variant="body1" sx={{ mt: 2 }}>
						{justification
							? justification
							: [
									STATUS.SOLICITADA_CORRECAO,
									STATUS.REJEITADA_ANALISE,
									STATUS.REJEITADO
							  ].includes(item.status)
							? 'A/o curador(a) não deixou nenhuma justificativa'
							: item.status === STATUS.AGUARDANDO_APROVACAO
							? `${collectionTypeGenre.toUpperCase()} ${collectionTypeName} está sendo analisad${collectionTypeGenre}.`
							: item.status === STATUS.APROVADO
							? `${collectionTypeGenre.toUpperCase()} ${collectionTypeName} foi aprovad${collectionTypeGenre} após análise.`
							: ''}
					</Typography>
				</Alert>
			)}
			{[STATUS.AGUARDANDO_APROVACAO, STATUS.RASCUNHO].includes(
				item.status
			) && (
				<>
					{emptyFields && emptyFields.length === 0 && (
						<Alert
							icon={<CheckIcon fontSize="inherit" />}
							severity="success"
							sx={{ my: 2 }}
						>
							{isCurator
								? 'Todos os campos da ficha técnica estão preenchidos.'
								: 'Parabéns! Todos os campos da ficha técnica foram preenchidos.'}
						</Alert>
					)}
					{emptyFields && emptyFields.length > 0 && (
						<Alert
							icon={<WarningIcon fontSize="inherit" />}
							severity="warning"
							sx={{ my: 2 }}
						>
							{isCurator
								? `${emptyFields.length} campo${
										emptyFields.length !== 1 ? 's' : ''
								  } da ficha técnica não ${
										emptyFields.length !== 1
											? 'foram preenchidos'
											: 'foi preenchido'
								  }.`
								: `${emptyFields.length} campo${
										emptyFields.length !== 1 ? 's' : ''
								  } da ficha técnica ${
										emptyFields.length !== 1
											? 'ficaram vazios'
											: 'ficou vazio'
								  }.`}
							{[STATUS.RASCUNHO].includes(item.status) &&
								!isCurator &&
								`Preencha os campos para facilitar que ${
									collectionTypeGenre === 'a' ? 'sua' : 'seu'
								} ${collectionTypeName} seja encontrad${collectionTypeGenre} nos resultados de busca do portal.`}
						</Alert>
					)}
				</>
			)}
			<S.MobileBlock className="img" onClick={onPreviewClick}>
				<img alt={title} src={cover_id || noImg} />
				{collection_type && (
					<S.Icon>
						<ContentOrCollectionTypeIcon id={collection_type.id} />
						<S.Type className="content-type">
							{collection_type.name}
						</S.Type>
					</S.Icon>
				)}
			</S.MobileBlock>
			<S.TabsContainer>
				<Tabs
					value={tabIndexValue}
					onChange={handleTabChange}
					indicatorColor="secondary"
				>
					<Tab label="Ficha Técnica" {...a11yProps(0)} />
					<Tab
						label={`Conteúdos d${collectionTypeGenre} ${collectionTypeName}`}
						{...a11yProps(1)}
					/>
				</Tabs>
			</S.TabsContainer>
			<CustomTabPanel value={tabIndexValue} index={0}>
				<CollectionSheet item={item} showEmptyFields={true} />
			</CustomTabPanel>
			<CustomTabPanel value={tabIndexValue} index={1}>
				<List
					contentIndicationIds={content_indications.map(
						(item) => item.id
					)}
				/>
			</CustomTabPanel>
		</>
	)

	return (
		<>
			<Box
				sx={{
					padding: 4,
					display: 'flex',
					flexDirection: 'row',
					gap: 5
				}}
			>
				<S.SideColumn>
					<S.Block className="img" onClick={onPreviewClick}>
						<img alt={title} src={cover_id || noImg} />
						{collection_type && (
							<S.Icon>
								<ContentOrCollectionTypeIcon
									id={collection_type.id}
								/>
								<S.Type className="content-type">
									{collection_type.name}
								</S.Type>
							</S.Icon>
						)}
					</S.Block>
					{!isPage &&
						![STATUS.REJEITADA_ANALISE, STATUS.REJEITADO].includes(
							item.status
						) && (
							<S.PreviewButton
								fullWidth
								onClick={onPreviewClick}
								variant="outlined"
								startIcon={<PreviewOutlinedIcon />}
							>
								Pré-Visualizar
							</S.PreviewButton>
						)}
				</S.SideColumn>
				<Box sx={{ flex: 1 }}>
					<Typography variant="h3">{title}</Typography>
					<Box sx={{ mt: 2 }}>{description}</Box>
					{!isPage && <CollectionDetailsInner />}
				</Box>
			</Box>
			{isPage && (
				<Box sx={{ display: 'flex', flexDirection: 'column' }}>
					{item.content_indications.length === 0 && (
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center'
							}}
						>
							<Box sx={{ fontWeight: 700 }}>
								Você ainda não enviou conteúdos para{' '}
								{collectionTypeGenre} {collectionTypeName}
							</Box>
							<Box sx={{ mt: 1 }}>
								Envie novos conteúdos ou adicione conteúdos já
								publicados
							</Box>
							<Box sx={{ my: 2 }}>
								<img
									src={noCollectionContents}
									width={116}
									height={90}
								/>
							</Box>
						</Box>
					)}
					<Button
						onClick={handleEditCollectionContents}
						variant="contained"
						color="secondary"
						sx={{ ml: 1, alignSelf: 'center', borderRadius: 5 }}
						startIcon={<EditIcon />}
					>
						{item.content_indications.length === 0
							? 'Adicionar conteúdos'
							: 'Adicionar ou remover conteúdos'}
					</Button>
					<CollectionDetailsInner />
				</Box>
			)}
		</>
	)
}

export default CollectionDetails
