import React, { useCallback, useState } from 'react'
import * as S from './styles'
import { Fab, ListItemText, Menu, MenuItem, Typography } from '@mui/material'
import Icon from '../../Icon'
import EditContentWrapper from '../EditContent/EditContentWrapper'
import history from '../../../app/history'
import { t } from 'i18next'
import { collectionTypeList } from '../../../app/_constants'
import { collectionsActions } from '../../../app/_actions'
import { useDispatch } from 'react-redux'
import EditCollectionWrapper from '../EditCollection/EditCollectionWrapper'
import { ReactComponent as ContentIcon } from '../../../assets/dashboard-menu/conteudos.svg'

const PageHeader = ({
	title,
	contentTypeSlug = null,
	setCreateNewContent = null
}) => {
	const dispatch = useDispatch()
	const [contentModalOpen, setContentModalOpen] = useState(false)
	const [selectedContentId, setSelectedContentId] = useState(null)

	const [collectionModalOpen, setCollectionModalOpen] = useState(false)
	const [collectionTypeObject, setCollectionTypeObject] = useState(null)

	const [anchorEl, setAnchorEl] = useState(null)
	const fabOpen = Boolean(anchorEl)

	const handleFabClick = (event) => {
		if (!contentTypeSlug) {
			setAnchorEl(event.currentTarget)
			return
		}

		if (contentTypeSlug === 'content') {
			if (setCreateNewContent) {
				setCreateNewContent(true)
				return
			}
			handleOpenContentModal()
			return
		}

		const collectionType = collectionTypeList.find(
			(i) => i.slug === contentTypeSlug
		)
		if (collectionType) {
			handleOpenCollectionModal(collectionType)
		}
	}

	const handleFabClose = () => {
		setAnchorEl(null)
	}

	const handleOpenContentModal = useCallback(() => {
		handleFabClose()
		if (setCreateNewContent) {
			setCreateNewContent(true)
			return
		}
		setContentModalOpen(true)
	}, [])

	const resetContentId = useCallback(() => {
		setContentModalOpen(false)
		setSelectedContentId(null)
	}, [])

	const onPublishClose = () => {
		history.push('/painel/conteúdo')
	}

	const handleOpenCollectionModal = useCallback((newCollectionTypeObject) => {
		dispatch(collectionsActions.cleanCollection())
		setCollectionTypeObject(newCollectionTypeObject)
		setCollectionModalOpen(true)
		handleFabClose()
	}, [])
	return (
		<>
			<S.Header>
				<Typography variant="h5" component="h1">
					{title}
				</Typography>
				<Fab
					color="secondary"
					aria-label="adicionar conteúdo"
					style={{ boxShadow: 'unset', zIndex: 1 }}
					onClick={handleFabClick}
					size="small"
				>
					<Icon
						type={'fas fa-plus'}
						style={{
							fontSize: '18px'
						}}
					/>
				</Fab>
				<Menu
					id="basic-menu"
					anchorEl={anchorEl}
					open={fabOpen}
					onClose={handleFabClose}
					MenuListProps={{
						'aria-labelledby': 'basic-button'
					}}
				>
					<MenuItem onClick={handleOpenContentModal}>
						<S.IconContainer>
							<ContentIcon />
						</S.IconContainer>
						<ListItemText>
							{t('content-fab.add-content', 'Enviar conteúdo')}
						</ListItemText>
					</MenuItem>

					{collectionTypeList.map((collectionTypeObjectInner) => {
						const Icon = collectionTypeObjectInner.icon
						return (
							<MenuItem
								key={collectionTypeObjectInner.slug}
								onClick={() =>
									handleOpenCollectionModal(
										collectionTypeObjectInner
									)
								}
							>
								<S.IconContainer>
									<Icon />
								</S.IconContainer>
								<ListItemText>
									{t('content-fab.add-collection', 'Nov') +
										collectionTypeObjectInner.genre +
										' ' +
										collectionTypeObjectInner.name.toLowerCase()}
								</ListItemText>
							</MenuItem>
						)
					})}
				</Menu>
			</S.Header>
			{(!contentTypeSlug || contentTypeSlug === 'content') &&
				!setCreateNewContent && (
					<EditContentWrapper
						selectedContentId={selectedContentId}
						setSelectedContentId={setSelectedContentId}
						contentModalOpen={contentModalOpen}
						setContentModalOpen={setContentModalOpen}
						resetContentId={resetContentId}
						onClose={onPublishClose}
					/>
				)}
			{(!contentTypeSlug || contentTypeSlug !== 'content') && (
				<EditCollectionWrapper
					collectionTypeObject={collectionTypeObject}
					collectionModalOpen={collectionModalOpen}
					setCollectionModalOpen={setCollectionModalOpen}
				/>
			)}
		</>
	)
}

export default PageHeader
