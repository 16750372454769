import styled from 'styled-components'
import Icon from '../../Icon'
import { Typography } from '@mui/material'

export const Wrapper = styled.div`
	display: flex;
	gap: 16px;
	flex-direction: column;
`

export const GridCell = styled.div`
	display: flex;
	background-color: #fff;
	padding: 16px 12px 24px 12px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 16px;
	border-radius: 4px;
	text-align: center;
	height: 100%;
`

export const GridCellLeft = styled(GridCell)`
	padding: 16px;
	align-items: flex-start;
	flex: 1 0 0;
	gap: 8px;
	text-align: left;
`

export const SmallGridCellLeft = styled(GridCellLeft)`
	padding: 8px 12px;
	gap: 4px;
`

export const SectionTitle = styled(Typography).attrs((props) => ({
	component: props.component
}))`
	padding: 0 16px;
	&&.MuiTypography-root {
		font-size: 18px;
	}
`

export const FavouritesWrappers = styled.div`
	padding: 16px;
	align-items: flex-start;
	flex: 1 0 0;
	gap: 8px;
`

export const StyledIcon = styled(Icon)`
	font-size: 100px;
	padding: 50px;
`

export const Description = styled.p`
	font-size: 14px;
`

export const WrappItem = styled.div`
	display: grid;
	grid-template-columns: repeat(3, auto);
	:not(:last-child) {
		border-bottom: 1px solid #f1f1f1;
	}
	div:first-child {
		min-width: 140px;
	}
	@media screen and (max-width: 782px) {
		display: flex;
		flex-wrap: wrap;
	}
`

export const ItemDesc = styled.div`
	display: flex;
	align-items: baseline;
	margin: 5px 0;
	i {
		margin-right: 10px;
		font-size: 30px;
		width: 40px;
	}
	h2 {
		margin-top: 0;
	}
`

export const WrappComments = styled.div`
	display: flex;
	align-items: center;
	i {
		padding: 20px;
		background: rgba(51, 51, 51, 0.1);
		border-radius: 50%;
		margin-right: 20px;
	}
`

export const CommentInfo = styled.span`
	font-size: 12px;
	color: rgba(51, 51, 51, 0.7);
`

export const Comment = styled.p`
	font-size: 14px;
`
