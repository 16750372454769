import React from 'react'
import CurationTable from '../../../components/Dashboard/DashboardPanel/CurationTable'

import * as S from './styles'

function Curation() {
	return (
		<S.Panel>
			<CurationTable />
		</S.Panel>
	)
}

export default Curation
