import styled from 'styled-components'

export const Background = styled.div`
	width: 100%;
	background: #fff;
	border-radius: 4px;
	display: flex;
	padding: 16px 16px 24px 16px;
	flex-direction: column;
	align-items: flex-start;
	gap
`

export const WrappPassword = styled.div`
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: flex-start;
	gap: 16px;
	max-width: 520px;
	width: 100%;
`
export const EditInfo = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: space-between;
	i {
		color: ${({ theme }) => theme.colors.primary};
	}
	form {
		width: 300px;
	}
`
export const InputWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	width: 100%;
	gap: 4px;
	.MuiFormLabel-root {
		margin: 0px;
		font-size: 15px;
		font-style: normal;
		font-weight: 400;
		color: var(--black-06, rgba(0, 0, 0, 0.6));
	}
`
