import styled from 'styled-components'

export const ProfileContainer = styled.div`
	width: 100%;
`

export const WrappInfo = styled.div`
	display: flex;
	> div {
		margin: 0px 40px;
	}
`
export const Background = styled.div`
	width: 100%;
	background: #fff;
	border-radius: 4px;
	display: flex;
	padding: 16px 16px 24px 16px;
	flex-direction: column;
	align-items: flex-start;
	gap: 16px;
`
export const Wrapper = styled.div`
	max-width: 520px;
	width: 100%;
	gap: 16px;
	display: flex;
	flex-direction: column;
`

export const PictureButtonWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: 4px;
`
export const AvatarInfo = styled.div`
	display: flex;
	align-items: center;
	gap: 16px;
`
export const InputWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: 4px;
	.MuiFormLabel-root {
		margin: 0px;
		font-size: 15px;
		font-style: normal;
		font-weight: 400;
		color: var(--black-06, rgba(0, 0, 0, 0.6));
	}
`

export const EditInfo = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: space-between;
	i {
		color: ${({ theme }) => theme.colors.primary};
	}
`

export const UserInfo = styled.div`
	width: 300px;
	> div {
		margin-bottom: 10px;
	}
	b {
		margin-left: 10px;
		font-weight: bold;
	}
	h1 {
		color: ${({ theme }) => theme.colors.secondary};
		margin-bottom: 0;
	}
`

const dot = () => ({
	alignItems: 'center',
	display: 'flex',
	fontSize: '16px',
	padding: '6.5px 4px',
	margin: '0',
	borderColor: '#85b7d9'
})

const placeholder = () => ({
	fontFamily: 'Lato, Helvetica Neue',
	fontSize: '16px',
	padding: '5px',
	color: 'rgb(199 199 204)'
})

const single = () => ({
	fontFamily: 'Lato, Helvetica Neue',
	fontSize: '17px',
	padding: 9
})

export const colourStyles = {
	container: (styles) => ({ ...styles, width: '99%' }),
	control: (styles) => ({ ...styles, backgroundColor: 'white' }),
	input: (styles) => ({ ...styles, ...dot() }),
	placeholder: (styles) => ({ ...styles, ...placeholder() }),
	singleValue: (styles) => ({ ...styles, ...single() }),
	option: (styles) => ({ ...styles, ...single() })
}
