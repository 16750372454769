import React, { useState, useEffect, useCallback } from 'react'
import debounce from 'lodash.debounce'
import { useDispatch, useSelector } from 'react-redux'
import { userActions } from '../../../app/_actions'

import DataTable from '../../DataTable'

import * as S from './styles'
import EditUser from './EditUser'
import { InputAdornment } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'

const columns = [
	{ name: 'name', label: 'Nome', width: '4' },
	{
		name: 'created_at',
		label: 'Criado em',
		format: 'DD/MM/YYYY'
	},
	{ name: 'email', label: 'E-mail' },
	{
		name: 'organization',
		label: 'Organização',
		customRender: (org) => org.name
	},
	{
		name: 'roles',
		label: 'Perfis',
		customRender: (roles) => roles.map((r) => r.name).join(', ')
	}
]

const ManageUsers = () => {
	const [open, setOpen] = useState(false)
	const [user, setUser] = useState({})
	const [pageUsers, setPageUsers] = useState(1)
	// @ts-ignore
	const { rows, loading } = useSelector((state) => state.users)
	// @ts-ignore
	const PER_PAGE = 10
	const dispatch = useDispatch()

	const getData = useCallback(() => {
		const getUser = userActions.getAll(pageUsers, PER_PAGE)
		dispatch(getUser)
	}, [dispatch, pageUsers, PER_PAGE])

	// eslint-disable-next-line
	const debounceUsers = useCallback(
		debounce(
			(value) => dispatch(userActions.getAll(pageUsers, PER_PAGE, value)),
			500
		),
		[]
	)

	const handleUserSearch = ({ target }) => {
		debounceUsers(target.value)
	}

	useEffect(() => {
		getData()
	}, [getData])

	const editUser = (user) => {
		setOpen(true)
		setUser(user)
	}

	const deleteUser = (user) => {
		dispatch(
			userActions.delete(user.id, () => {
				getData()
			})
		)
	}

	return (
		<S.Panel>
			<S.StyledInput
				fullWidth
				slotProps={{
					input: {
						startAdornment: (
							<InputAdornment position="start">
								<SearchIcon />
							</InputAdornment>
						)
					}
				}}
				variant="outlined"
				onChange={handleUserSearch}
				placeholder="Pesquisar usuária..."
			/>
			<DataTable
				prefixKey="user_"
				data={rows ? rows.users : []}
				totalResults={rows ? rows.totalResults : 0}
				loading={loading}
				page={pageUsers}
				setPage={setPageUsers}
				columns={columns}
				onEdit={editUser}
				onDelete={deleteUser}
				deleteTitle="Exclusão do usuário"
				deleteMessage="Tem certeza que deseja excluir o usuário selecionado? Todas as informações da conta, conteúdos e séries cadastradas serão perdidos!"
			/>
			<EditUser
				open={open}
				setOpen={setOpen}
				user={user}
				onUserUpate={getData}
			/>
		</S.Panel>
	)
}

export default ManageUsers
