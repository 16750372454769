import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { userActions } from '../../../app/_actions'

import * as S from './styles'
import { Button, InputLabel, TextField } from '@mui/material'

const fields = [
	{
		type: 'password',
		name: 'currentPassword',
		label: 'Senha atual',
		icon: 'lock',
		placeholder: 'Digite sua senha atual',
		required: true
	},
	{
		type: 'password',
		name: 'newPassword',
		label: 'Nova senha',
		icon: 'lock',
		placeholder: 'Digite uma nova senha',
		required: true
	},
	{
		type: 'password',
		name: 'passwordConfirmation',
		label: 'Confirmar nova senha',
		icon: 'lock',
		placeholder: 'Confirme a nova senha',
		required: true
	}
]

const EditPassword = () => {
	const dispatch = useDispatch()

	const [submitted, setSubmitted] = useState(false)
	const [inputs, setInputs] = useState({
		currentPassword: '',
		newPassword: '',
		passwordConfirmation: ''
	})

	function handleChange(e) {
		e.preventDefault()
		const { name, value } = e.target
		setInputs((inputs) => ({ ...inputs, [name]: value }))
	}

	function handleSubmit(e) {
		e.preventDefault()

		const { currentPassword, newPassword, passwordConfirmation } = inputs
		setSubmitted(true)
		if (currentPassword && newPassword && passwordConfirmation) {
			dispatch(
				userActions.changePassword(
					currentPassword,
					newPassword,
					passwordConfirmation
				)
			)
		}
	}

	return (
		<S.Background>
			<S.WrappPassword>
				{fields.map((field, key) => (
					<S.InputWrapper key={key}>
						<InputLabel>{`${field.label} ${
							field.required ? '*' : ''
						}`}</InputLabel>
						<TextField
							key={key}
							type={field.type}
							fullWidth
							error={
								submitted &&
								field.required &&
								!inputs[field.name]
							}
							name={field.name}
							placeholder={field.placeholder}
							onChange={handleChange}
						/>
					</S.InputWrapper>
				))}

				<Button variant="contained" onClick={handleSubmit}>
					Alterar senha
				</Button>
			</S.WrappPassword>
		</S.Background>
	)
}

export default EditPassword
