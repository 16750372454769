import React, { useCallback, useState } from 'react'
import * as ManageContentWrapperStyles from '../../../pages/Dashboard/Content/ManageContentWrapper/styles.js'

import CustomTabPanel from '../CustomTabPanel'
import PageHeader from '../PageHeader/index.js'
import { Tab, Tabs } from '@mui/material'
import ManageUsers from '../ManageUsers/index.js'
import ManageOrgs from '../ManageOrgs/index.js'
import AllContent from '../../../pages/Dashboard/Content/AllContent/index.js'

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`
	}
}

const ManageAllTabs = () => {
	const [tabIndexValue, setTabIndexValue] = useState(0)
	const handleTabChange = useCallback((_, newValue) => {
		setTabIndexValue(newValue)
	}, [])
	return (
		<>
			<PageHeader title="Gerenciar" />

			<ManageContentWrapperStyles.TabsContainer>
				<Tabs
					value={tabIndexValue}
					onChange={handleTabChange}
					indicatorColor="secondary"
				>
					<Tab label="Usuárias/os" {...a11yProps(0)} />
					<Tab label="Organizações" {...a11yProps(1)} />
					<Tab label="Conteúdos" {...a11yProps(2)} />
				</Tabs>
			</ManageContentWrapperStyles.TabsContainer>
			<CustomTabPanel value={tabIndexValue} index={0}>
				<ManageUsers />
			</CustomTabPanel>
			<CustomTabPanel value={tabIndexValue} index={1}>
				<ManageOrgs />
			</CustomTabPanel>
			<CustomTabPanel value={tabIndexValue} index={2}>
				<AllContent />
			</CustomTabPanel>
		</>
	)
}

export default ManageAllTabs
