import React, { memo, useState } from 'react'
import { useSelector } from 'react-redux'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import EditIcon from '@mui/icons-material/Edit'
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	Typography
} from '@mui/material'

import * as S from './styles'
import ContentDetails from '../ContentDetails'
import { STATUS } from '../../../constants'
import Icon from '../../Icon'

function PublishContent({
	open,
	handleClose,
	startEdit = null,
	title = 'Enviar conteúdo para Avaliação',
	onDelete = null,
	onSubmitToPublish = null,
	onApprove = null,
	onAskCorrection = null,
	onReject = null
}) {
	const [confirmEditOpen, setConfirmEditOpen] = useState(false)
	const [showMenu, setShowMenu] = useState(false)
	// @ts-ignore
	const { object } = useSelector((state) => state.content)
	const [anchorEl, setAnchorEl] = useState(null)

	const handleEllipsisButtonClick = (event) => {
		setShowMenu(true)
		setAnchorEl(event.currentTarget)
	}

	const localStartEdit = () => {
		setConfirmEditOpen(false)
		startEdit()
	}

	const handleEdit = () => {
		if (
			[STATUS.APROVADO, STATUS.AGUARDANDO_APROVACAO].includes(
				object.status
			)
		) {
			setConfirmEditOpen(true)
			return
		}
		localStartEdit()
	}

	const handleMobileEdit = () => {
		handleEdit()
		setShowMenu(false)
	}

	const handleMobileDelete = () => {
		onDelete()
		setShowMenu(false)
	}

	const handleConfirmEditClose = () => {
		setConfirmEditOpen(false)
	}

	const handleMobileClose = () => {
		handleClose()
		setShowMenu(false)
	}

	return (
		<>
			<S.StyledDialog
				open={open}
				onClose={handleMobileClose}
				scroll="paper"
				fullWidth={true}
				maxWidth="lg"
				aria-labelledby="dialog-title"
				aria-describedby="dialog-description"
			>
				<S.StyledDialogTitle id="dialog-title">
					{[STATUS.REJEITADA_ANALISE, STATUS.REJEITADO].includes(
						object.status
					)
						? 'Detalhes do conteúdo'
						: title}
				</S.StyledDialogTitle>
				<S.StyledDialogContent>
					<ContentDetails
						item={object}
						isCurator={onApprove !== null}
					/>
				</S.StyledDialogContent>
				<S.StyledDialogActions>
					<S.StyledButton onClick={handleMobileClose} size="large">
						Fechar
					</S.StyledButton>
					<S.StyledRightButtons>
						{onDelete && (
							<S.HideOnMobileButton
								onClick={onDelete}
								variant="outlined"
								color="error"
								startIcon={<DeleteOutlineOutlinedIcon />}
								disabled={[
									STATUS.REJEITADA_ANALISE,
									STATUS.REJEITADO
								].includes(object.status)}
							>
								Excluir
							</S.HideOnMobileButton>
						)}
						{startEdit && (
							<S.HideOnMobileButton
								onClick={handleEdit}
								variant="outlined"
								startIcon={<EditIcon />}
								disabled={[
									STATUS.REJEITADA_ANALISE,
									STATUS.REJEITADO
								].includes(object.status)}
							>
								Editar
							</S.HideOnMobileButton>
						)}
						<S.ShowOnMobileButton
							onClick={handleEllipsisButtonClick}
							variant="outlined"
						>
							<Icon type="fa fa-ellipsis-h" />
						</S.ShowOnMobileButton>
						{showMenu && (
							<Menu
								id="basic-menu"
								anchorEl={anchorEl}
								open={open}
								onClose={handleMobileClose}
								MenuListProps={{
									'aria-labelledby': 'basic-button'
								}}
							>
								<MenuItem
									onClick={handleMobileEdit}
									disabled={[
										STATUS.REJEITADA_ANALISE,
										STATUS.REJEITADO
									].includes(object.status)}
								>
									<ListItemIcon>
										<EditIcon />
									</ListItemIcon>
									<ListItemText primary="Editar" />
								</MenuItem>
								<MenuItem
									onClick={handleMobileDelete}
									color="error"
									disabled={[
										STATUS.REJEITADA_ANALISE,
										STATUS.REJEITADO
									].includes(object.status)}
								>
									<ListItemIcon>
										<DeleteOutlineOutlinedIcon color="error" />
									</ListItemIcon>
									<ListItemText
										disableTypography
										primary={
											<Typography
												variant="body1"
												color="error"
											>
												Excluir
											</Typography>
										}
									/>
								</MenuItem>
							</Menu>
						)}
						{onSubmitToPublish && (
							<S.StyledButton
								onClick={onSubmitToPublish}
								variant="contained"
								color="secondary"
								disabled={[
									STATUS.AGUARDANDO_APROVACAO,
									STATUS.APROVADO,
									STATUS.REJEITADA_ANALISE,
									STATUS.REJEITADO
								].includes(object.status)}
							>
								Enviar
							</S.StyledButton>
						)}
						{onReject && (
							<S.StyledButton
								onClick={onReject}
								variant="outlined"
								color="error"
							>
								Rejeitar
							</S.StyledButton>
						)}
						{onAskCorrection && (
							<S.StyledButton
								onClick={onAskCorrection}
								variant="outlined"
								color="warning"
							>
								Solicitar correção
							</S.StyledButton>
						)}
						{onApprove && (
							<S.StyledButton
								onClick={onApprove}
								variant="contained"
								color="secondary"
							>
								Aprovar conteúdo
							</S.StyledButton>
						)}
					</S.StyledRightButtons>
				</S.StyledDialogActions>
			</S.StyledDialog>
			<Dialog open={confirmEditOpen} onClose={handleConfirmEditClose}>
				<DialogTitle>Voltar a rascunho</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Atenção!{' '}
						{object.status === STATUS.AGUARDANDO_APROVACAO
							? 'Você já enviou o conteúdo para aprovação.'
							: 'Este conteúdo já foi aprovado.'}
					</DialogContentText>
					<DialogContentText sx={{ my: 2 }}>
						Se editar, o conteúdo voltará a ser rascunho.
					</DialogContentText>
					<DialogContentText sx={{ my: 2 }}>
						Depois de editar, você poderá enviar novamente para
						aprovação.
					</DialogContentText>
					<DialogContentText>
						Tem certeza que quer editar?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						variant="contained"
						color="warning"
						onClick={localStartEdit}
					>
						Sim, editar!
					</Button>
					<Button onClick={handleConfirmEditClose}>Cancelar</Button>
				</DialogActions>
			</Dialog>
		</>
	)
}

export default memo(PublishContent)
