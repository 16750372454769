import React from 'react'
import { Switch } from 'react-router-dom'
import PrivateRoute from '../../../components/Dashboard/PrivateRoute'

import ManageAll from '../../../components/Dashboard/ManageAll'

import * as S from './styles'
import ManageAllTabs from '../../../components/Dashboard/ManageAllTabs'

const Management = () => {
	return (
		<S.Panel>
			<Switch>
				<PrivateRoute
					exact
					path={'/painel/administração/visão-geral'}
					component={ManageAll}
				/>
				<PrivateRoute
					exact
					path={'/painel/administração/gerenciar'}
					component={ManageAllTabs}
				/>
			</Switch>
		</S.Panel>
	)
}

export default Management
