import React from 'react'
import { Switch } from 'react-router-dom'
import PrivateRoute from '../../../components/Dashboard/PrivateRoute'

import * as S from './styles'
import MyAccount from '../../../components/Dashboard/MyAccount'
import EditPassword from '../../../components/Dashboard/EditPassword'
import MyComments from '../../../components/Dashboard/MyComments'
import MyFavorites from '../../../components/Dashboard/MyFavorites'

const Account = () => {
	return (
		<S.Panel>
			{/* <GridColumn width={5}>
				<Form size="large">
					<List link size="massive">
						{itemsMenu.map((item, key) => (
							<List.Item key={key}>
								<NavLink
									activeStyle={{ fontWeight: 'bold' }}
									to={`/painel/minha-conta/${item.href}`}
								>
									{item.label}
								</NavLink>
							</List.Item>
						))}
					</List>
				</Form>
			</GridColumn> */}
			<Switch>
				<PrivateRoute
					exact
					path={'/painel/minha-conta/perfil'}
					component={MyAccount}
				/>
				<PrivateRoute
					path="/painel/minha-conta/alterar-senha"
					component={EditPassword}
				/>
				<PrivateRoute
					path="/painel/minha-conta/minhas-mensagens"
					component={MyComments}
				/>
				<PrivateRoute
					path="/painel/minha-conta/favoritos"
					component={MyFavorites}
				/>
			</Switch>
		</S.Panel>
	)
}

export default Account
