import React from 'react'

import { Typography } from '@mui/material'

const ContentTypesSummary = ({ title, icon, value }) => {
	return (
		<>
			{icon}
			<Typography variant="subtitle2" color="black">
				{value}
			</Typography>
			<Typography variant="caption">{title}</Typography>
		</>
	)
}

export default ContentTypesSummary
