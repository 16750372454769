import { metricsConstants } from '../_constants'

export function metrics(
	state = {
		generalMetrics: [],
		contentTypesMetrics: [],
		themesMetrics: []
	},
	action
) {
	switch (action.type) {
		case metricsConstants.SUCCESS:
			return {
				...state,
				generalMetrics: action.data || []
			}
		case metricsConstants.CONTENT_TYPES_SUCCESS:
			return {
				...state,
				contentTypesMetrics: action.data || []
			}
		case metricsConstants.THEMES_SUCCESS:
			return {
				...state,
				themesMetrics: action.data || []
			}
		case metricsConstants.FAILURE:
		case metricsConstants.REQUEST:
		case metricsConstants.CONTENT_TYPES_FAILURE:
		case metricsConstants.CONTENT_TYPES_REQUEST:
		case metricsConstants.THEMES_FAILURE:
		case metricsConstants.THEMES_REQUEST:
		default:
			return state
	}
}
