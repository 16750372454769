import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ReactComponent as ConteudosIcon } from '../../../assets/dashboard-menu/conteudos.svg'
import GroupAddIcon from '@mui/icons-material/GroupAddOutlined'
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWorkOutlined'

import * as S from './styles'
import history from '../../../app/history'
import { metricsActions } from '../../../app/_actions'
import EditContentWrapper from '../EditContent/EditContentWrapper'
import EditCollectionWrapper from '../EditCollection/EditCollectionWrapper'
import ContentOrCollectionSummary from './ContentOrCollectionSummary'
import { collectionTypeList } from '../../../app/_constants'
import PageHeader from '../PageHeader'
import { Trans, useTranslation } from 'react-i18next'
import Grid from '@mui/material/Unstable_Grid2/Grid2'
import { Box, Button, Typography } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import noCollectionContents from '../../../assets/no-collections.png'
import CurationTable from './CurationTable'
import MyFavorites from '../MyFavorites'
import ContentTypesSummary from './ContentTypesSummary'
import ContentOrCollectionTypeIcon from '../../ContentOrCollectionTypeIcon'
import ThemeIcon from '../../ThemeIcon'

const Dashboard = () => {
	const { t } = useTranslation()

	const dispatch = useDispatch()
	const { generalMetrics, contentTypesMetrics, themesMetrics } = useSelector(
		// @ts-ignore
		(state) => state.metrics
	)
	// @ts-ignore
	const { user } = useSelector((state) => state.authentication)

	const [contentModalOpen, setContentModalOpen] = useState(false)
	const [selectedContentId, setSelectedContentId] = useState(null)

	const [collectionModalOpen, setCollectionModalOpen] = useState(false)
	// const [selectedCollectionId, setSelectedCollectionId] = useState(null)

	const isCuratorChief = user.roles.includes('ROLE_CURADOR_CHEFE')
	const isCurator = user.roles.includes('ROLE_CURADOR')
	const isAdmin = user.roles.includes('ROLE_ADMIN')

	useEffect(() => {
		dispatch(metricsActions.getPainelMetrics())
		dispatch(metricsActions.getContentTypesMetrics())
		dispatch(metricsActions.getThemesMetrics())
	}, [dispatch])

	const shuffledContentTypesMetrics = useMemo(
		() =>
			contentTypesMetrics
				.map((value) => ({ value, sort: Math.random() }))
				.sort((a, b) => a.sort - b.sort)
				.map(({ value }) => value),
		[contentTypesMetrics]
	)

	const shuffledThemesMetrics = useMemo(
		() =>
			themesMetrics
				? themesMetrics
						.map((value) => ({ value, sort: Math.random() }))
						.sort((a, b) => a.sort - b.sort)
						.map(({ value }) => value)
				: [],
		[themesMetrics]
	)

	const handleOpenContentModal = useCallback(() => {
		setContentModalOpen(true)
	}, [])

	const resetContentId = useCallback(() => {
		setContentModalOpen(false)
		setSelectedContentId(null)
	}, [])

	const onPublishClose = () => {
		history.push('/painel/conteúdo')
	}

	if (!generalMetrics || !contentTypesMetrics || !themesMetrics) {
		return null
	}

	const collectionTypeItems = collectionTypeList.map(
		(collectionTypeObjectInner) => {
			return {
				title:
					collectionTypeObjectInner.plural +
					' ' +
					t('dashboard.info-card-avaliable', 'disponíveis'),
				icon: undefined,
				value: generalMetrics[`${collectionTypeObjectInner.slug}s`],
				subItem: [
					{
						title: 'Favoritos',
						icon: 'fad fa-star',
						value: generalMetrics[
							`favorite_${collectionTypeObjectInner.slug}s`
						],
						iconStyle: { color: '#F7CB03' }
					},
					{
						title: 'Gostei',
						icon: 'fad fa-thumbs-up',
						iconStyle: { color: 'rgb(62, 166, 255)' },
						value: generalMetrics[
							`liked_${collectionTypeObjectInner.slug}s`
						]
					}
				],
				collectionTypeSlug: collectionTypeObjectInner.slug
			}
		}
	)

	const items = [
		{
			title: 'Conteúdos publicados',
			icon: ConteudosIcon,
			value: generalMetrics.content_indications,
			subItem: [
				{
					title: 'Favoritos',
					icon: 'fad fa-star',
					value: generalMetrics.favorite_content_indications,
					iconStyle: { color: '#F7CB03' }
				},
				{
					title: 'Gostei',
					icon: 'fad fa-thumbs-up',
					iconStyle: { color: 'rgb(62, 166, 255)' },
					value: generalMetrics.liked_content_indications
				}
			],
			collectionTypeSlug: null
		},
		{
			title: 'Pessoas cadastradas',
			icon: GroupAddIcon,
			value: generalMetrics.users,
			subItem: [],
			collectionTypeSlug: null
		},
		{
			title: 'Organizações cadastradas',
			icon: MapsHomeWorkIcon,
			value: generalMetrics.organizations,
			subItem: [],
			collectionTypeSlug: null
		},
		...collectionTypeItems
	]

	return (
		<S.Wrapper>
			<PageHeader title={t('dashboard.dashboard.title', 'Painel')} />
			<Grid container spacing={2}>
				<Grid md={5} sm={12} xs={12}>
					<S.GridCell>
						<Box sx={{ my: 2 }}>
							<img
								src={noCollectionContents}
								width={116}
								height={90}
							/>
						</Box>
						<Typography variant="subtitle2" color="black">
							{t(
								'dashboard.send-content',
								'Envie conteúdos para a biblioteca digital'
							)}
						</Typography>
						<div>
							<Typography variant="body2">
								<Trans i18nKey="dashboard.publish-content">
									Publique documentos e conteúdos digitais{' '}
									<br />
									sobre áreas protegidas
								</Trans>
							</Typography>
						</div>
						<Button
							variant="contained"
							onClick={handleOpenContentModal}
							color="secondary"
							startIcon={<EditIcon />}
							sx={{ borderRadius: 50 }}
						>
							{t('dashboard.create-content', 'Criar')}
						</Button>
					</S.GridCell>
				</Grid>

				<Grid md={7} sm={12} xs={12} container spacing={2}>
					{items.map((item, key) => {
						return (
							<Grid key={key} md={4} sm={6} xs={6}>
								<S.GridCellLeft>
									<ContentOrCollectionSummary
										title={item.title}
										icon={item.icon}
										collectionTypeSlug={
											item.collectionTypeSlug
										}
										// iconStyle={item.iconStyle}
										value={item.value}
									/>
								</S.GridCellLeft>
							</Grid>
						)
					})}
				</Grid>
			</Grid>
			<S.SectionTitle variant="h5" component="h2">
				Estatísticas da Biblioteca Proteja
			</S.SectionTitle>

			<Grid container spacing={2}>
				{shuffledContentTypesMetrics.map((item, key) => {
					return (
						<Grid key={key} md={1.5} sm={6} xs={6}>
							<S.SmallGridCellLeft>
								<ContentTypesSummary
									title={item.name}
									icon={
										<ContentOrCollectionTypeIcon
											id={item.id}
										/>
									}
									value={item.total}
								/>
							</S.SmallGridCellLeft>
						</Grid>
					)
				})}
				{shuffledThemesMetrics.map((item, key) => {
					const Icon = ThemeIcon({
						id: item.id,
						size: 20
					})
					return (
						Icon && (
							<Grid key={key} md={1.5} sm={6} xs={6}>
								<S.SmallGridCellLeft>
									<ContentTypesSummary
										title={item.name}
										icon={Icon}
										value={item.total}
									/>
								</S.SmallGridCellLeft>
							</Grid>
						)
					)
				})}
			</Grid>

			{(isAdmin || isCuratorChief || isCurator) && (
				<CurationTable
					header={
						<S.SectionTitle variant="h5" component="h2">
							Pendentes de aprovação
						</S.SectionTitle>
					}
				/>
			)}
			<MyFavorites
				title="Salvos recentemente"
				showMore={false}
				header={
					<S.SectionTitle variant="h5" component="h2">
						Salvos recentemente
					</S.SectionTitle>
				}
			/>
			<EditContentWrapper
				selectedContentId={selectedContentId}
				setSelectedContentId={setSelectedContentId}
				contentModalOpen={contentModalOpen}
				setContentModalOpen={setContentModalOpen}
				resetContentId={resetContentId}
				onClose={onPublishClose}
			/>
			<EditCollectionWrapper
				collectionTypeObject={null}
				collectionModalOpen={collectionModalOpen}
				setCollectionModalOpen={setCollectionModalOpen}
			/>
		</S.Wrapper>
	)
}

export default Dashboard
