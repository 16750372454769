import styled from 'styled-components'

export const Header = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0px 16px;
`

export const Title = styled.span`
	font-size: 23px;
	font-weight: 400;
	color: ${({ theme }) => theme.colors.primary};
`

export const IconContainer = styled.div`
	margin-right: 8px;
`
