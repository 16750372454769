import React from 'react'

import { ReactComponent as biodiversidade } from '../assets/themes/biodiversidade.svg'
import { ReactComponent as floresta } from '../assets/themes/floresta-e-agua.svg'
import { ReactComponent as pressoes } from '../assets/themes/pressoes-e-ameacas.svg'
import { ReactComponent as economia } from '../assets/themes/economia-verde.svg'
import { ReactComponent as povos } from '../assets/themes/povos-e-territorios.svg'
import { ReactComponent as gestao } from '../assets/themes/gestao.svg'

const ThemeIcon = ({ id, size = 16, color = '#194D48', style = {} }) => {
	const contentTypeIcons = {
		1: biodiversidade,
		2: floresta,
		3: pressoes,
		4: economia,
		5: povos,
		6: gestao
	}

	if (!id) {
		return <></>
	}
	const Icon = contentTypeIcons[id]
	if (!Icon) {
		return null
	}
	const sizePx = `${size}px`
	return (
		<Icon
			size={size}
			fill={color}
			style={{
				width: sizePx,
				minWidth: sizePx,
				height: sizePx,
				minHeight: sizePx,
				...style
			}}
		/>
	)
}

export default ThemeIcon
