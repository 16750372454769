import styled from 'styled-components'
import Chip from '@mui/material/Chip'
import Autocomplete from '@mui/material/Autocomplete'

export const StyledAutocomplete = styled(Autocomplete)`
	input {
		border: 0;
	}
`

export const StyledChip = styled(Chip)`
	font-size: 15px !important;
`

export const InputWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: 4px;
	.MuiFormLabel-root {
		margin: 0;
		font-size: 15px;
		font-style: normal;
		font-weight: 400;
		color: var(--black-06, rgba(0, 0, 0, 0.6));
	}
	input::placeholder {
		font-size: 15px;
		color: rgba(0, 0, 0, 0.35);
	}
	input {
		color: rgba(0, 0, 0, 0.65) !important;
	}
	.MuiInputBase-root {
		min-height: 42px;
		padding: 0px 12px !important;
	}
	.MuiFormHelperText-root {
		font-size: 12px;
	}
	.MuiOutlinedInput-root {
		padding: 0;
	}
`
