import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Popup, Modal, Icon } from 'semantic-ui-react'
import { contentActions } from '../../../../app/_actions'
import { userService } from '../../../../app/_services'
import AsyncSelect from '../../../AsyncSelect'

import DataTable from '../../../DataTable'
import PublishContent from '../../PublishContent'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import * as S from './styles'
import { Typography } from '@mui/material'

const columns = [
	{ name: 'title', label: 'Título' },
	{ name: 'author', label: 'Autor' },
	{
		name: 'created_at',
		label: 'Criado em',
		format: 'DD/MM/YYYY HH:mm'
	},
	{
		name: 'user',
		label: 'Criado por',
		customRender: (value, email) => {
			return (
				<div>
					{value}
					<Popup
						content={`Enviar e-mail para o ${email}`}
						flowing
						size="huge"
						hoverable
						trigger={
							<Icon
								name="mail outline"
								color="grey"
								size="large"
								style={{ margin: '0 5px', cursor: 'pointer' }}
								onClick={() =>
									// @ts-ignore
									(window.location = `mailto:${email}`)
								}
							/>
						}
					/>
				</div>
			)
		}
	},
	{
		name: 'curator',
		label: 'Curador responsável',
		customRender: (value, email) => {
			return (
				<div>
					{value}
					<Popup
						content={`Enviar e-mail para o ${email}`}
						flowing
						size="huge"
						hoverable
						trigger={
							<Icon
								name="mail outline"
								color="grey"
								size="large"
								style={{ margin: '0 5px', cursor: 'pointer' }}
								onClick={() =>
									// @ts-ignore
									(window.location = `mailto:${email}`)
								}
							/>
						}
					/>
				</div>
			)
		}
	},
	{
		name: 'status',
		label: 'Status',
		status: true,
		info: true,
		textInfoColumn: 'approve_justification',
		textInfoColumn2: 'reprove_justification'
	}
]

function ContentToApproval({
	isAdmin,
	isCuratorChief,
	isCurator,
	contentCurators
}) {
	const dispatch = useDispatch()
	const [page, setPage] = useState(1)
	const [curator, setCurator] = useState(null)
	const [openCuratorModal, setOpenCuratorModal] = useState(false)
	const [openPublishModal, setOpenPublishModal] = useState(false)
	const [openDefineModal, setOpenDefineModal] = useState(false)
	const [defineText, setDefineText] = useState('')
	const [errorDefineText, setErrorDefineText] = useState(false)
	const [defineType, setDefineType] = useState('')
	const PER_PAGE = 5

	const {
		data,
		object: selectedItem,
		loading
		// @ts-ignore
	} = useSelector((state) => state.content)

	const getData = useCallback(() => {
		dispatch(
			contentActions.getAllContentToApproval(
				page,
				PER_PAGE,
				contentCurators
			)
		)
	}, [dispatch, page, PER_PAGE, contentCurators])

	const handleCloseCuratorModal = () => {
		setOpenCuratorModal(false)
		setCurator(null)
		dispatch(contentActions.cleanContent())
	}

	const handleClosePublishModal = () => {
		setOpenPublishModal(false)
		dispatch(contentActions.cleanContent())
	}

	const handleCurator = (item) => {
		dispatch(contentActions.getContentById(item.id))
		setOpenCuratorModal(true)
		setCurator(
			item.curator.id
				? { value: item.curator.id, label: item.curator.name }
				: null
		)
	}

	const confirmCurator = () => {
		if (curator) {
			dispatch(
				contentActions.setCurator(
					selectedItem.id,
					curator.value,
					() => {
						handleCloseCuratorModal()
						getData()
					}
				)
			)
			handleCloseCuratorModal()
		}
	}

	const handleCloseDefineModal = () => {
		setDefineText('')
		setErrorDefineText(false)
		dispatch(contentActions.cleanContent())
		setOpenDefineModal(false)
	}

	const handleApprove = (newSelectedItem) => {
		setOpenPublishModal(true)
		dispatch(contentActions.getContentById(newSelectedItem.id))
	}

	const defineContent = (type) => {
		setOpenDefineModal(true)
		setDefineType(type)
	}

	const handleReject = () => {
		dispatch(contentActions.getContentById(selectedItem.id))
		defineContent('reject-analysis')
	}

	const handleDoApprove = () => {
		defineContent('approve')
	}

	const handleAskCorrection = () => {
		defineContent('correct')
	}

	const confirmContent = () => {
		if (selectedItem && (defineText || defineType === 'approve')) {
			dispatch(
				contentActions.defineContent(
					selectedItem.id,
					defineType,
					defineText,
					() => {
						getData()
						handleCloseDefineModal()
						handleClosePublishModal()
					}
				)
			)
			return
		}

		setErrorDefineText(true)
		dispatch(contentActions.cleanContent())
	}

	const getDefineModalTitle = () => {
		switch (defineType) {
			case 'reject-analysis':
				return 'Justificativa para REJEIÇÃO do conteúdo'
			case 'correct':
				return 'Observações para CORREÇÃO do conteúdo'
			case 'approve':
				return 'Razões para APROVAÇÃO do conteúdo (opcional)'
		}
	}

	const getDefineModalBody = () => {
		switch (defineType) {
			case 'reject-analysis':
				return 'Quais as razões para rejeitar'
			case 'correct':
				return 'O que deve ser alterado?'
			case 'approve':
				return 'Justifique as razões da aprovação'
		}
	}

	useEffect(() => {
		getData()
	}, [getData])

	return (
		<>
			{loading || (data && data.content && data.content.length > 0) ? (
				<DataTable
					prefixKey="toApproval_"
					data={data ? data.content : []}
					totalResults={data ? data.totalResults : 0}
					loading={loading}
					page={page}
					setPage={setPage}
					columns={columns}
					setCurator={
						isAdmin || isCuratorChief ? handleCurator : null
					}
					onApprove={
						isAdmin || isCuratorChief || isCurator
							? handleApprove
							: null
					}
					onReject={isCurator ? handleReject : null}
				/>
			) : (
				<S.NoContent>
					<Typography
						variant="body1"
						sx={{ color: 'rgba(0, 0, 0, 0.60)' }}
					>
						Nenhum conteúdo novo disponível para avaliação.
					</Typography>
				</S.NoContent>
			)}
			<Modal onClose={handleCloseCuratorModal} open={openCuratorModal}>
				<Modal.Header>
					Definir curador responsável pela aprovação do conteúdo
				</Modal.Header>
				<Modal.Content image>
					<Modal.Description>
						<label>Curador</label>
						<AsyncSelect
							loadOptions={userService.loadUsersCurator}
							onChange={setCurator}
							placeholder="Selecione um/a usuário/a"
							value={curator}
						/>
					</Modal.Description>
				</Modal.Content>
				<Modal.Actions>
					<Button color="black" onClick={handleCloseCuratorModal}>
						Cancelar
					</Button>
					<Button
						content="Definir curador/a"
						labelPosition="right"
						icon="checkmark"
						disabled={!curator}
						onClick={confirmCurator}
						positive
					/>
				</Modal.Actions>
			</Modal>
			<PublishContent
				open={openPublishModal}
				handleClose={handleClosePublishModal}
				startEdit={null}
				title="Aprovação do conteúdo selecionado"
				onDelete={null}
				onSubmitToPublish={null}
				onReject={handleReject}
				onAskCorrection={handleAskCorrection}
				onApprove={handleDoApprove}
			/>
			<Dialog open={openDefineModal} onClose={handleCloseDefineModal}>
				<DialogTitle>{getDefineModalTitle()}</DialogTitle>
				<DialogContent>
					<S.StyledTextArea
						style={{ width: '100%' }}
						value={defineText}
						error={
							errorDefineText ? 'Este campo é obrigatório' : ''
						}
						onChange={({ target }) => {
							setDefineText(target.value)
							setErrorDefineText(false)
						}}
						placeholder={getDefineModalBody()}
						rows={5}
					/>
				</DialogContent>
				<DialogActions>
					<Button color="black" onClick={handleCloseDefineModal}>
						Cancelar
					</Button>
					<Button
						content="Confirmar"
						labelPosition="right"
						icon="checkmark"
						disabled={loading}
						onClick={confirmContent}
						positive
					/>
				</DialogActions>
			</Dialog>
		</>
	)
}

export default ContentToApproval
