export const metricsConstants = {
	REQUEST: 'METRICS_REQUEST',
	SUCCESS: 'METRICS_SUCCESS',
	FAILURE: 'METRICS_FAILURE',

	CONTENT_TYPES_REQUEST: 'CONTENT_TYPES_REQUEST',
	CONTENT_TYPES_SUCCESS: 'CONTENT_TYPES_SUCCESS',
	CONTENT_TYPES_FAILURE: 'CONTENT_TYPES_FAILURE',

	THEMES_REQUEST: 'THEMES_REQUEST',
	THEMES_SUCCESS: 'THEMES_SUCCESS',
	THEMES_FAILURE: 'THEMES_FAILURE'
}
