import styled from 'styled-components'

export const Wrapper = styled.div`
	display: flex;
	padding: 0px;
	align-items: center;
	gap: 8px;
	align-self: stretch;
`
export const Block = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	width: 220px;
	border-radius: 8px;
	overflow: hidden;
	transition: box-shadow 0.3s ease-in-out;
	:hover {
		box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2);
		cursor: pointer;
	}
	:hover .content-type {
		opacity: 1;
	}
	.content {
		position: absolute;
		display: flex;
		align-items: center;
		height: 100%;
		width: 100%;
		justify-content: center;
		overflow: hidden;
	}
	img {
		display: flex;
		width: 220px;
		height: 126px;
		pointer-events: none;
		object-fit: cover;
	}
	@media screen and (max-width: 1024px) {
		height: 280px;
		img {
			height: 280px;
		}
	}
	@media screen and (max-width: 782px) {
		display: none;
	}
`

export const Icon = styled.div`
	display: flex;
	padding: 4px 12px 4px 12px;
	align-items: center;
	gap: 6px;
	align-self: stretch;
	background: var(--brown-lighten-5, #efebe9);
	i {
		font-size: 16px;
		color: #194d4894;
	}
`

export const Type = styled.p`
	color: #194d48;
	font-size: 12px;
	opacity: 0;
	transition: opacity 0.3s linear;
`

export const ContentInfoContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 4px;
	flex: 1 0 0;
	padding: 0px 8px;
	align-self: stretch;
	width: 100%;
`
export const Title = styled.div`
	display: flex;
	align-items: flex-start;
	gap: 10px;
	align-self: stretch;
	color: #194d48;
	font-family: Roboto;
	font-size: 18px;
	font-style: normal;
	font-weight: 700;
	line-height: 24px;
	letter-spacing: 0.25px;
	width: 100%;
	:hover {
		text-decoration: underline;
		cursor: pointer;
	}
`

export const Description = styled.div`
	color: var(--black-06, rgba(0, 0, 0, 0.6));
	font-family: Roboto;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 21px; /* 142.857% */
	letter-spacing: 0.25px;
	width: 100%;
`

export const KeywordsContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	gap: 8px;
	width: 100%;
`
export const Keyword = styled.div`
	display: flex;
	white-space: nowrap;
	padding: 2px 12px;
	align-items: center;
	gap: 6px;
	border-radius: 12px;
	border: 1px solid var(--brown-lighten-4, #d7ccc8);
	color: var(--primary, #194d48);
	text-align: center;
	font-family: Roboto;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	:hover {
		cursor: pointer;
		color: #fff;
		background-color: var(--primary, #194d48);
	}
`
