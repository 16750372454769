import React from 'react'
import { withRouter } from 'react-router-dom'

import ManageContentWrapper from '../ManageContentWrapper'

const AllContent = () => {
	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				gap: '8px',
				paddingTop: '16px',
				overflow: 'hidden'
			}}
		>
			<ManageContentWrapper isUserContent={false} />
		</div>
	)
}

export default withRouter(AllContent)
