import styled from 'styled-components'

export const Wrapper = styled.div`
	display: flex;
	width: 336px;
	padding: 0px;
	flex-direction: column;
	align-items: center;
	gap: 8px;
	align-self: stretch;
	:hover {
		cursor: pointer;
	}
`
export const Block = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	border-radius: 8px;
	overflow: hidden;
	transition: box-shadow 0.3s ease-in-out;
	:hover {
		box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2);
	}
	:hover .proteja-content-type {
		opacity: 1;
	}
	:hover img {
		transform: scale(1.1);
	}
	.content {
		position: absolute;
		display: flex;
		align-items: center;
		height: 100%;
		width: 100%;
		justify-content: center;
		overflow: hidden;
	}
	.lazyload-wrapper {
		height: 205px;
		width: 100%;
		overflow: hidden;
	}
	img {
		display: flex;
		width: 100%;
		height: 205px;
		pointer-events: none;
		object-fit: cover;
		transition: all 0.3s ease;
	}
	@media screen and (max-width: 1024px) {
		height: 280px;
		img {
			height: 280px;
		}
	}
`

export const TopWrapper = styled.div`
	display: flex;
	z-index: 9;
	position: absolute;
	bottom: 0;
	width: 100%;
	flex-direction: column;
	padding: 4px 12px 4px 12px;
	align-items: center;
	gap: 6px;
	align-self: stretch;
	background: #efebe9;
`

export const ContentTypeWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 6px;
	align-self: stretch;
	i {
		font-size: 16px;
		color: #194d4894;
	}
`

export const Type = styled.p`
	color: #194d48;
	font-size: 12px;
	opacity: 0;
	transition: opacity 0.3s linear;
`

export const Title = styled.div`
	color: #194d48;
	padding-left: 8px;
	padding-right: 8px;
	font-family: Roboto;
	font-size: 15px;
	font-style: normal;
	font-weight: 500;
	line-height: 20px;
	width: 100%;
	:hover {
		text-decoration: underline;
	}
`
