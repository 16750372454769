import { TextArea } from 'semantic-ui-react'
import styled from 'styled-components'

export const StyledTextArea = styled(TextArea)`
	height: auto;
`

export const NoContent = styled.div`
	padding: 24px 16px;
	flex-direction: column;
	align-items: flex-start;
	gap: 16px;
	border-radius: 4px;
	background: #fff;
`
