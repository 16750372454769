import React, { useCallback, useRef, useState } from 'react'
import { withRouter, useParams } from 'react-router-dom'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import { Input } from 'semantic-ui-react'
import debounce from 'lodash.debounce'

import * as S from './styles'
import List from '../List'
import CustomTabPanel from '../../../../components/Dashboard/CustomTabPanel'
import ManageCollections from '../ManageCollections'
import { collectionTypeList } from '../../../../app/_constants'
import PageHeader from '../../../../components/Dashboard/PageHeader'

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`
	}
}

const ManageCollectionsWrapper = ({ isUserCollection = true }) => {
	const { collectionTypeParam } = useParams()
	const collectionTypeObject = collectionTypeList.find(
		(item) => item.plural.toLowerCase() === collectionTypeParam
	)
	const [createNewCollection, setCreateNewCollection] = useState(false)
	const [tabIndexValue, setTabIndexValue] = useState(0)
	const [searchValue, setSearchValue] = useState('')
	const searchValueRef = useRef('')

	// eslint-disable-next-line
	const debounceCollection = useCallback(
		debounce((value) => {
			setSearchValue(value)
		}, 500),
		[]
	)

	const handleCollectionSearch = useCallback(
		({ target }) => {
			debounceCollection(target.value)
		},
		[debounceCollection]
	)

	const handleTabChange = useCallback((_, newValue) => {
		setTabIndexValue(newValue)
	}, [])

	if (!collectionTypeObject) {
		console.log('error finding this url')
		return <></>
	}

	const placeholder = `Pesquisar ${collectionTypeObject.name.toLowerCase()}...`

	return (
		<>
			<S.Wrapper>
				{isUserCollection ? (
					<>
						<PageHeader
							title={
								(collectionTypeObject.genre === 'a'
									? 'Minhas'
									: 'Meus') +
								' ' +
								collectionTypeObject.plural
							}
							contentTypeSlug={collectionTypeObject.slug}
						/>
						<>
							<S.TabsContainer>
								<Tabs
									value={tabIndexValue}
									onChange={handleTabChange}
									indicatorColor="secondary"
								>
									<Tab label="Gerenciar" {...a11yProps(0)} />
									<Tab
										label={`Ver Publicad${collectionTypeObject.genre}s`}
										{...a11yProps(1)}
									/>
								</Tabs>
								<Input
									icon="search"
									onChange={handleCollectionSearch}
									placeholder={placeholder}
								/>
							</S.TabsContainer>
							<CustomTabPanel value={tabIndexValue} index={0}>
								<ManageCollections
									collectionTypeObject={collectionTypeObject}
									searchValue={searchValue}
									searchValueRef={searchValueRef}
									isUserCollection={isUserCollection}
									createNewCollection={createNewCollection}
									setCreateNewCollection={
										setCreateNewCollection
									}
								/>
							</CustomTabPanel>
							<CustomTabPanel value={tabIndexValue} index={1}>
								<List
									searchValue={searchValue}
									searchValueRef={searchValueRef}
								/>
							</CustomTabPanel>
						</>
					</>
				) : (
					<>
						<Input
							icon="search"
							onChange={handleCollectionSearch}
							placeholder={placeholder}
						/>
						<ManageCollections
							collectionTypeObject={collectionTypeObject}
							searchValue={searchValue}
							searchValueRef={searchValueRef}
							isUserCollection={isUserCollection}
							createNewCollection={createNewCollection}
							setCreateNewCollection={setCreateNewCollection}
						/>
					</>
				)}
			</S.Wrapper>
		</>
	)
}

export default withRouter(ManageCollectionsWrapper)
